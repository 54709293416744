html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

#video-container { 
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; 
    height: 100vh; 
    display: flex;
    justify-content: center; 
    align-items: center; 
    background-color: black; 
}

#video-container video {
    width: 100%;  
    height: 100%; 
    object-fit: cover; 
}

.widget-body .progress-bar {
    position: fixed !important;
    bottom: 5px !important;
}

.widget-body {
    padding:1rem;
}
.video-options-widget {
    position: absolute;
    top:0;
    right: 0;
}
.video-options-widget button {
   background:none !important;
   border:none !important;
   color:#FFF !important;
   box-shadow: none !important;
   margin:0.25rem;
}
.video-options-widget button::before {
    display: none !important;
}
.widget-logo {
    position: absolute;
    top:0;
    left:0;
}
.widget-playbtn {
    position:fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);
    background: none !important;
    border: none !important;
    color:#FFF !important;
    font-size:4em;
    cursor: pointer !important;
    z-index: 10;
}

.widget-body .dropdown-menu {
    background-color: #111 !important;
    border:solid 1px rgba(184, 0, 184, 0.2);
}
.widget-body .dropdown-menu a {
    color:#FFF !important;
}
.widget-body .dropdown-menu a {
    transition: background 0.3s; 
}

.widget-body .dropdown-menu a:hover,
.widget-body .dropdown-menu a:focus,
.widget-body .dropdown-menu a:active {
    background: #aa0e6c !important;
}
